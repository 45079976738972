<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2">
                <div class="pull-right">
                    <b-button variant='success' :to="{ name: 'WorkPositionsCreate' }">Новая должность</b-button>
                </div>
            </b-card-header>

            <table class='table'>
                <thead class='table-dark'>
                    <tr class='bold'>
                        <td>#</td>
                        <td>Ключ</td>
                        <td>Наименование</td>
                        <td>Действия</td>
                    </tr>
                </thead>

                <draggable v-bind="options" v-model="workPositions" @end='move'>
                    <tr
                        :key="workPosition.key"
                        @dblclick='goTo( workPosition.id )'
                        v-for="workPosition in workPositions"
                    >
                        <td class="move"><b class='fas fa-bars'></b></td>
                        <td>{{ workPosition.key }}</td>
                        <td>{{ workPosition.name }}</td>
                        <td>
                            <b-button variant='outline-primary' size='md' @click='goTo( workPosition.id )'>
                                <i class='fas fa-pen'></i>
                            </b-button>

                            <b-button variant='outline-danger' size='md' @click='remove( workPosition.id )'>
                                <i class='fas fa-times'></i>
                            </b-button>
                        </td>
                    </tr>
                </draggable>

                <tbody v-if='Array.isArray( workPositions ) && workPositions.length <= 0'>
                    <tr class='bold'>
                        <td colspan='4'>Нет доступных должностей</td>
                    </tr>
                </tbody>
            </table>
        </b-card>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import ErrorsBag from '@utils/errorsBag';
    import { WORK_POSITIONS_ENDPOINT } from '@utils/endpoints';

    export default {
        name: "WorkPositionsList",
        components: { draggable, ErrorsBagList },
        computed: {
            options() {
                return {
                    tag: 'tbody',
                    animation: 200,
                    handle: '.move',
                    disabled: false,
                    ghostClass: 'ghost',
                    group: 'description'
                };
            }
        },
        data() {
            return {
                ErrorsBag,
                workPositions: null
            };
        },
        methods: {
            goTo( id ) {
                this.$router.push( { name: 'WorkPositionsEdit', params: { id: id } } );
            },
            remove( id ) {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить этот элемент?' ) ) {
                    this.$http
                        .delete( WORK_POSITIONS_ENDPOINT + '/' + id )
                        .then( null, response => ErrorsBag.fill( response.body, false ) )
                        .finally( () => this.fetchWorkPositions() );
                }
            },
            move( evt ) {
                ErrorsBag.discard();

                if ( evt.newIndex === evt.oldIndex ) {
                    return null;
                }

                let item = this.workPositions[ evt.newIndex ];
                if ( !item ) {
                    return null;
                }

                let id = item.id;
                let params = {
                    key: item.key,
                    order: evt.newIndex + 1
                };

                this.$http
                    .put( WORK_POSITIONS_ENDPOINT + '/' + id + '/move', params )
                    .then( null, response => ErrorsBag.fill( response.body, false ) )
                    .finally( () => this.fetchWorkPositions() );
            },

            fetchWorkPositions() {
                this.workPositions = null;
                let params = { orders: { order: 'asc', name: 'asc' }, limit: 0 };

                this.$http
                    .get( WORK_POSITIONS_ENDPOINT, { params } )
                    .then(
                        response => this.workPositions = response.data.data.data,
                        response => {
                            this.workPositions = [];
                            ErrorsBag.fill( response.body, false );
                        }
                    );
            }
        },
        created() {
            this.fetchWorkPositions();
        }
    }
</script>

<style scoped lang='sass'>
    .move
        cursor: pointer

    .bold
        font-weight: bold

    .ghost
        opacity: 0.5
        background: #c8ebfb
</style>
